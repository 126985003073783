import React, { Component } from 'react'
import DownloadIcon from 'react-icons/lib/fa/download'
import MailIcon from 'react-icons/lib/fa/envelope-o'
import cn from 'clsx'
import Layout from '@layout'
import FullscreenImage from '@components/FullscreenImage'
import BookingBgImage from '@images/booking-bg.jpg'
import { Subscribe } from '@utils'

class BookingPage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loaded: false
		}
		this.onImageLoaded = this.onImageLoaded.bind(this)
	}

	onImageLoaded() {
		this.setState({ loaded: true })
	}

	render() {
		const pageClass = cn('bookingpage', { loaded: this.state.loaded })
		return (
			<Layout>
			<div className={pageClass}>
				<FullscreenImage image={BookingBgImage} loaded={this.onImageLoaded} />

				<div className="container">
					<div className="booking-box">
						<div className="booking-info">
							<div className="border borderOne" />
							<div className="border borderTwo" />
							<div className="border borderThree" />

							<p>
								The Human Nature is an indie rock band from the South East of Norway. They have been
								making music and played venues since 2014, and already released 3 singles and 1 EP.
								Their debut album "Kairos" will be expected early 2018, and is a mixture of analog
								synthesizers, heavy drums, vocal harmonies and a distorted Les Paul. This creates
								the sound of The Human Nature.
							</p>
							<h2>Booking</h2>
							<p>
								Lars Martin Torp <br />
								+47 901 94 377 <br />
								booking@thehumannature.no
							</p>
						</div>
						<div className="links">
							<a
								href="https://www.dropbox.com/sh/hpm1ltooj0l9z7q/AACAovKQUlyR3xFvgUY--Fd8a?dl=0"
								target="_blank"
							>
								<DownloadIcon /> Press kit
							</a>
							<button onClick={Subscribe}>
								<MailIcon /> Subscribe to newsletter
							</button>
						</div>
					</div>
				</div>
			</div>
			</Layout>
		)
	}
}

export default BookingPage
